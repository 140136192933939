import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
//import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
//import { getAuth, connectAuthEmulator } from 'firebase/auth'
//import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import { getFirestore} from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBq6sEJUiUbH4z4XD0oi6e1_Ql2KZ2exWM",
  authDomain: "logic-finance-leads.firebaseapp.com",
  databaseURL: "https://logic-finance-leads-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "logic-finance-leads",
  storageBucket: "logic-finance-leads.appspot.com",
  messagingSenderId: "728390250394",
  appId: "1:728390250394:web:db5a18e5e3c878d2f8d423"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')

export default firebaseConfig;
export const db = getFirestore();
export const auth = getAuth();
export const functions = getFunctions(app, "europe-west1");

//connectAuthEmulator(auth, "http://127.0.0.1:9099");
//connectFirestoreEmulator(db, '127.0.0.1', 5002);
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);

