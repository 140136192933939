import {createRouter, createWebHistory} from "vue-router"
import { getAuth, onAuthStateChanged } from "firebase/auth";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", component: () => import("../views/SignIn.vue") },

        //Sigend In App
        { 
            path: "/in-app", 
            component: () => import("../components/InApp.vue"), 
            meta: { 
                requiresAuth: true 
            },
            children: [
                { 
                    path: "homepage", 
                    component: () => import("../views/HomeView.vue") 
                },
                { 
                    path: "leads", 
                    component: () => import("../views/LeadOrders.vue") 
                },
                { 
                    path: "milestones", 
                    component: () => import("../views/MileStones.vue") 
                },
                { 
                    path: "settings", 
                    component: () => import("../views/UserSettings.vue") 
                },
                { 
                    path: "payments", 
                    component: () => import("../views/PaymentStripe.vue") 
                },
                ]
        },



        { path: "/sign-in", component: () => import("../views/SignIn.vue") },
        { path: "/register", component: () => import("../views/RegisterUser.vue") },
    ],
});

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
            },
            reject
        );
    })
}


router.beforeEach(async (to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next();
        } else {
            next("/sign-in");
        }

    } else {
        next();
    }
});


export default router;